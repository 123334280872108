import React, { Suspense } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.snow.css";
import "./scss/main.scss";
import configureStoreProd from "./Pages/config/configureStore.prod";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { lazy } from "react";

const { persistor, store } = configureStoreProd();

function App() {

  const routes = [
    { url: "/login", path: "Login" },
    { url: "/signup", path: "Signup" },
    { url: "/", path: "Home" },
    { url: "/dashboard", path: "Dashboard" },
    { url: "*", path: "NotFoundPage" },
    { url: "/profile", path: "Profile" },
    { url: "/profile/:tab", path: "Settings" },
    { url: "/forgotpassword", path: "Forgotpassword" },
    { url: "/resetpassword", path: "Resetpassword" },

    { url: "/job", path: "Jobs" },
    { url: "/job/edit/:id", path: "Jobs/AddEdit" },
    { url: "/job/add", path: "Jobs/AddEdit" },
    { url: "/job/detail/:id", path: "Jobs/View" },

    { url: "/project", path: "Projects" },
    { url: "/project/edit/:id", path: "Projects/AddEdit" },
    { url: "/project/add", path: "Projects/AddEdit" },
    { url: "/project/detail/:id", path: "Projects/View" },

    { url: "/", element: <Navigate to="/login" /> },

    { url: "/blogs", path: "Blogs" },
    { url: "/blog-detail/:id", path: "Blogs/View" },

    { url: "/contactus", path: "Contactus" },

    { url: "/about-us", path: "Aboutus" },

    { url: "/refund", path: "Refund" },

    { url: "/privacy", path: "Privacy" },

    { url: "/terms", path: "Terms" },

    { url: "/cancelpayment", path: "CancelPayment" },

    { url: "/complete/job/:id?", path: "CompoleteJob" },

    { url: "/transaction", path: "Transaction" },

    { url: "/payslip", path: "ContractorTransaction" }
  ];

  sessionStorage.clear();


  return (
    <>
      <Provider store={store}>
        <PersistGate loading={"loading ..."} persistor={persistor}>
          <Suspense
            fallback={
              <div id="loader" className="loaderDiv">
                <div>
                  <img
                    src="/assets/img/loader.gif"
                    alt="logo"
                    className="loaderlogo"
                  />
                </div>
              </div>
            }
          >
            <Router>
              <Routes>
                {routes.map((itm) => {
                  const Element = lazy(() => import(`./Pages/${itm.path}`));
                  return (
                    <Route
                      path={itm.url}
                      element={itm.path ? <Element /> : itm.element}
                    />
                  );
                })}
              </Routes>
            </Router>
          </Suspense>
        </PersistGate>
      </Provider>
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
}

export default App;
